import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { AutoGraphRounded, GraphicEqRounded, Lightbulb } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';


const sectionItems = [
    {
      id: 1,
      icon: <AutoGraphRounded sx={{ fontSize: 25 }} color="white" />,
      sentence:
        'View Advanced Analytics about your posts including application click-through rate, post engagement metrics, and demographic information.',
    },
    {
      id: 2,
      icon: <Lightbulb sx={{ fontSize: 25 }} color="white" />,
      sentence:
        'Access Custom Recommendations for groups that you should be targeting based on your needs and prior post success.',
    },
  ];

const Analytics = () => {

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: "#FBFBFF",
        paddingTop: '40px',
        paddingBottom: '40px',
        gap: '20px'
        
    }}>
        <Grid item sx={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        }}>
          <Typography variant="h6" fontWeight={550}sx={{
            paddingBottom: '3px',
          }}>
            Analytics and Recommendations
          </Typography>

          <Typography sx={{
                  opacity: '0.7',
                  paddingBottom: '10px',
                  fontSize: '18px',
          }}>
            Optimize your recruiting process and make better hires
        </Typography>
        </Grid>

        <Grid container sx = {{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  gap: { xs: 5, md: 5, lg: 10},
        }}>
      {sectionItems.map((item) => (
        <Grid
          item
          xs={10}
          md={6}
          lg={3.5}
          key={item.id}
          sx = {{     
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            bgcolor: 'white',
            borderRadius: 5,
            height: 250,
            padding: 5,
            paddingTop: 20,
            paddingBottom: 20,
            boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 0%), 0px 12px 17px 2px rgb(0 0 0 / 0%), 0px 5px 22px 4px rgb(0 0 0 / 3%)',
            
            }}
        >
          <Avatar sx ={{
            bgcolor: "#482fd7",
            width: 55,
            height: 55
            
          }}>
            {item.icon}
          </Avatar>
          <Typography variant = "h7" sx = {{
            marginTop: 2,
          }}>{item.sentence}</Typography>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
};

export default Analytics;