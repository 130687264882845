import { Search, KeyboardBackspace } from "@mui/icons-material";
import {
	Box,
	Button,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	getClubs,
	getPost,
	modifyGroupsOnPost,
} from "../../utilities/firebase";
import GroupPaper from "./GroupPaper";

const ClubSelect = () => {
	const [search, setSearch] = useState("");
	const [startingGroups, setStartingGroups] = useState([]);
	const [groups, setGroups] = useState([]);
	const [selected, setSelected] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const postId = location.state.postId;
	useEffect(() => {
		const fetchData = async () => {
			const postData = await getPost(postId);
			const clubs = await getClubs("northwestern");
			if (postData.posted_to) {
				let preSelected = [];
				for (let postRef of postData.posted_to) {
					preSelected.push(postRef._key.path.segments.at(-1));
				}
				setSelected(preSelected);
				setStartingGroups(preSelected);
			}
			setGroups(clubs);
		};

		fetchData().catch(console.error);
	}, []);

	const _handleSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const handleSetSelected = (id, isAdding) => {
		if (isAdding) {
			setSelected((prev) => [...prev, id]);
		} else {
			setSelected((prev) => prev.filter((item) => item !== id));
		}
	};

	const handleBack = () => {
		navigate("/postOpportunity/details", {
			state: { postId: postId },
		});
	};

	const handleSubmit = async () => {
		let selectedGroups = groups.filter((group) => {
			return selected.includes(group[1]);
		});
		let removedGroups = groups.filter((group) => {
			return startingGroups.includes(group[1]) && !selected.includes(group[1]);
		});
		selectedGroups = selectedGroups.map((group) => {
			return { school_id: group[3], group_id: group[1] };
		});
		removedGroups = removedGroups.map((group) => {
			return { school_id: group[3], group_id: group[1] };
		});
		console.log(removedGroups);
		await modifyGroupsOnPost(postId, selectedGroups, removedGroups);
		alert("Successfully Sent to Clubs");

		navigate("/myOpportunities");
	};

	return (
		<Box
			marginX={"100px"}
			marginY={"2rem"}
			display='flex'
			flexDirection='column'
			gap={"25px"}>
			<Box display='flex' justifyContent={"space-between"} alignItems='center'>
				<Box
					onClick={handleBack}
					display='flex'
					gap={"14px"}
					alignItems={"center"}>
					<KeyboardBackspace />
					<Typography>Back</Typography>
				</Box>
				<Button onClick={handleSubmit} variant='contained'>
					Submit
				</Button>
			</Box>
			<div>
				<Typography variant='h5' fontWeight={500}>Post an Opportunity</Typography>
				<Typography variant='body1'>3. Select Clubs</Typography>
			</div>
			<Typography variant='h7' fontWeight={500}>
				Click sideways arrows to view group descriptions
			</Typography>
			<Box>
				<TextField
					id='input-with-icon-textfield'
					fullWidth
					value={search}
					onChange={_handleSearchChange}
					placeholder='Search for groups...'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Search />
							</InputAdornment>
						),
					}}
					variant='standard'
				/>
			</Box>
			<Box display={"flex"} flexDirection='column' gap='28px'>
				{groups.map((group) => {
					if (!group[0].name.toLowerCase().includes(search.toLowerCase())) {
						return;
					}
					if (selected.indexOf(group[1]) > -1) {
						return (
							<GroupPaper
								active={true}
								group={group}
								key={group[1]}
								handleSetSelected={handleSetSelected}
							/>
						);
					}
					return (
						<GroupPaper
							active={false}
							group={group}
							key={group[1]}
							handleSetSelected={handleSetSelected}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default ClubSelect;
