import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";

import Navbar from "./Navbar.jsx";

const DefualtLayout = () => {
	return (
		<div className='container'>
			<Stack spacing={2} sx = {{
				height: '100vh',
				overflowY: "hidden"
			}}>
				<Navbar />
				<Box>
					<Outlet />
				</Box>
			</Stack>
		</div>
	);
};

export default DefualtLayout;
