import * as React from "react";
import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Typography,
	Menu,
	Container,
	Stack,
	MenuItem,
	CssBaseline,
	Button,
	Tooltip,
	Avatar,
	Divider,
	ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../images/Logo.svg";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, signOutUser } from "../utilities/firebase";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";
import { Logout, PersonAdd, Settings, LockPerson } from "@mui/icons-material";

const Navbar = () => {
	const [userStatus, setUserStatus] = useState(false);
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const { currentUser, hasCompany } = useAuth();

	React.useEffect(() => {
		if (currentUser) {
			setUserStatus(true);
		}
	}, [currentUser]);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const navigate = useNavigate();

	const handleChangeUserStatus = async () => {
		if (currentUser) {
			await signOutUser();
			navigate("/signin");
		} else {
			navigate("/signin");
		}
	};

	const handleNavigateSettings = () => {
		navigate("/settings");
	};

	const handleNavigatePosts = () => {
		navigate("/myOpportunities");
	};

	const handleNavigateHome = () => {
		navigate("/");
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const pages = ["Settings", userStatus];

	return (
		<div className='Navbar'>
			<CssBaseline />
			<AppBar position='relative' color='white' m={0} sx={{ zIndex: 10 }}>
				<Container maxWidth='xl'>
					<Toolbar disableGutters>
						<Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
							<img src={Logo} width={40} alt='Logo' />
						</Box>
						<Link
							component={RouterLink}
							to={`${currentUser ? "/myOpportunities" : "/"}`}
							variant='h5'
							sx={{
								mr: 2,
								display: { xs: "none", md: "flex" },
								flexGrow: 1,
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "inherit",
								textDecoration: "none",
							}}>
							Inbound
						</Link>
						<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleOpenNavMenu}
								color='inherit'>
								<MenuIcon />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", md: "none" },
								}}>
								{pages.map((page) => (
									<MenuItem key={page} onClick={handleCloseNavMenu}>
										<Typography textAlign='center'>{page}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box sx={{ display: { xs: "flex", md: "none" }, mr: 2 }}>
							<img src={Logo} width={40} alt='Logo' />
						</Box>
						<Link
							component={RouterLink}
							to='/'
							variant='h5'
							sx={{
								mr: 2,
								display: { xs: "flex", md: "none" },
								flexGrow: 1,
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "inherit",
								textDecoration: "none",
							}}>
							Inbound
						</Link>

						<Box
							sx={{
								flexGrow: 1,
								justifyContent: "center",
								display: { xs: "none", md: "flex" },
							}}></Box>

						<Stack
							sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
							direction='row'
							spacing={2}>
							<Box
								display='flex'
								sx={{
									alignItems: "center",
									justifyItems: "center",
								}}>
								{currentUser && (
									<>
										<Button
											component={RouterLink}
											to='/postOpportunity'
											variant='contained'
											disabled={!hasCompany}
											sx={{
												mr: 2,
												display: "flex",
												fontFamily: "Poppins",
												fontWeight: 500,
												textDecoration: "none",
												flexGrow: 1,
											}}>
											Post an Opportunity
										</Button>
										<Tooltip title='Account settings'>
											<IconButton
												onClick={handleClick}
												size='small'
												sx={{ mr: 2 }}
												aria-controls={open ? "account-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}>
												<Avatar sx={{ width: 32, height: 32 }} />
											</IconButton>
										</Tooltip>
										<Menu
											anchorEl={anchorEl}
											id='account-menu'
											open={open}
											onClose={handleClose}
											onClick={handleClose}
											PaperProps={{
												elevation: 0,
												sx: {
													overflow: "visible",
													filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
													mt: 1.5,
													"& .MuiAvatar-root": {
														width: 32,
														height: 32,
														ml: -0.5,
														mr: 1,
													},
													"&:before": {
														content: '""',
														display: "block",
														position: "absolute",
														top: 0,
														right: 14,
														width: 10,
														height: 10,
														bgcolor: "background.paper",
														transform: "translateY(-50%) rotate(45deg)",
														zIndex: 10,
													},
												},
											}}
											transformOrigin={{
												horizontal: "right",
												vertical: "top",
											}}
											anchorOrigin={{
												horizontal: "right",
												vertical: "bottom",
											}}>
											<MenuItem component={RouterLink} to='/settings'>
												<Avatar /> Profile
											</MenuItem>
											<Divider />
											<MenuItem component={RouterLink} to='/settings'>
												<ListItemIcon>
													<Settings fontSize='small' />
												</ListItemIcon>
												Settings
											</MenuItem>
											{/* <MenuItem component={RouterLink} to='/privatePath'>
												<ListItemIcon>
													<LockPerson fontSize='small' />
												</ListItemIcon>
												Private Path
											</MenuItem> */}
											<MenuItem onClick={handleChangeUserStatus}>
												<ListItemIcon>
													<Logout fontSize='small' />
												</ListItemIcon>
												Logout
											</MenuItem>
										</Menu>
									</>
								)}

								{!currentUser && (
									<Typography
										variant='body3'
										noWrap
										component='a'
										onClick={handleChangeUserStatus}
										sx={{
											mr: 2,
											display: "flex",
											fontFamily: "Poppins",
											fontWeight: 500,
											color: "inherit",
											textDecoration: "none",
											flexGrow: 1,
											cursor: "pointer",
										}}>
										{currentUser ? "Sign Out" : "Sign In"}
									</Typography>
								)}
							</Box>
						</Stack>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
};
export default Navbar;
