import React, { useContext, useState, useEffect } from "react";
import { auth, getRecruiter } from "../utilities/firebase";
import { Navigate } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [loadingUser, setLoadingUser] = useState(true);
	const [hasCompany, setHasCompany] = useState(false);
	const [reqCompany, setReqCompany] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		setLoadingUser(true);
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				let userData = await getRecruiter(user.uid);
				setCurrentUser(userData);
				if (userData) {
					setHasCompany("company" in userData);
					setReqCompany(userData.requested ? true : false);
				}
			} else {
				setCurrentUser(null);
				setHasCompany(false);
				setReqCompany(false);
			}
			setLoadingUser(false);
		});

		return unsubscribe;
	}, []);

	useEffect(() => {
		async function fetchData() {
			setLoadingUser(true);
			if (currentUser) {
				let userData = await getRecruiter(currentUser.user_id);
				setCurrentUser(userData);
				if (userData) {
					setHasCompany("company" in userData);
					setReqCompany(userData.requested ? true : false);
				}
			} else {
				setCurrentUser(null);
				setHasCompany(false);
				setReqCompany(false);
			}
			setLoadingUser(false);
		}
		fetchData();
		setUpdate(false);
	}, [update]);

	function refresh() {
		setUpdate(true);
	}

	const value = {
		currentUser,
		hasCompany,
		reqCompany,
		refresh,
	};

	if (loadingUser) return;
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
