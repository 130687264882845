import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
	const navigate = useNavigate();

	const navigateNext = () => {
		navigate("/signup", { replace: true });
	};

	const navigateSignUp = () => {
		window.open("https://yxsgezb0ebs.typeform.com/to/TeJBmWsj");
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				paddingBottom: 10,
				paddingTop: 10,
			}}>
			<Grid
				container
				sx={{
					paddingTop: "50px",
					paddingBottom: "50px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: { xs: "80%", md: "100%", lg: "100%" },
					maxWidth: { xs: "500px", sm: "500px", md: "750px", lg: "750px" },
					gap: { xs: "5px", sm: "5px", lg: "10px" },
					flexDirection: "column",
					bgcolor: "white",
					borderRadius: 5,
					padding: 8,
					boxShadow:
						"0px 7px 8px -4px rgb(0 0 0 / 0%), 0px 12px 17px 2px rgb(0 0 0 / 0%), 0px 5px 22px 4px rgb(0 0 0 / 5%)",
				}}>
				<Grid>
					<Typography
						variant='h5'
						fontWeight={550}
						sx={{
							paddingBottom: "15px",
							fontSize: "18px",
						}}>
						Hire the students that{" "}
						<span style={{ color: "#482fd7" }}>you want</span> with Inbound
					</Typography>
				</Grid>

				<Button
					onClick={navigateNext}
					variant='contained'
					color='primary'
					sx={{ width: "200px", fontSize: "16px" }}>
					Sign Up
				</Button>
			</Grid>
		</Box>
	);
};

export default SignUp;
