import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NoPosts = ({ canPost }) => {
	const navigate = useNavigate();

	function handleClick() {
		navigate("/postOpportunity");
	}

	return (
		<Box
			sx={{
				p: "40px",
				m: "10px",
				borderRadius: "40px",
			}}>
			<Box sx={{ display: "flex", mb: 2, flexDirection: "column", alignItems: "center" }}>
				{canPost && <Typography variant='h5' sx={{ mb: 5 }}>
					No existing posts. Click below to create a post.
					</Typography>}
				{canPost && <Button onClick={handleClick} variant='contained' disabled={!canPost} sx={{minWidth: "200px", maxWidth: "300px"}}>
					New Post
					</Button>}
				{!canPost && <Typography variant='h5' sx={{ mb: 5, textAlign: "center" }}>
					Your company join request has not been accepted yet.
				</Typography>}
			</Box>
		</Box>
	);
};

export default NoPosts;
