import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";

import Members from "./Members/Members";
import Requests from "./Requests/Requests";

function TabPanel(props) {
	const {
		children,
		value,
		index,

		...other
	} = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`club-manage-tabpanel-${index}`}
			aria-labelledby={`club-manage-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `club-manage-tab-${index}`,
		"aria-controls": `club-manage-tabpanel-${index}`,
	};
}

export default function BasicTabs({ company, members, requests }) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='Company Manage Tabs'>
					<Tab label='Members' {...a11yProps(0)} />
					<Tab label='Requests' {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Members members={members} company={company} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Requests requests={requests} company={company} />
			</TabPanel>
		</Box>
	);
}
