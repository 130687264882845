import React from "react";
import { Box, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";

const Img = styled("img")({
	margin: "auto",
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
	objectFit: "cover",
});

const PostedToIcons = ({ clubs }) => {
	if (clubs.length > 0) {
		return (
			<Box sx={{ display: "flex",	overflow: "auto", flexWrap: "nowrap"
		}}>
				{clubs.map((club) => {
					return (
						<Fade in={clubs.length > 0}>
							<Img
								key={club.name}
								alt={club.name}
								src={club.logo}
								title={club.name}
								sx={{
									width: 108,
									height: 108,
									borderRadius: "12px",
									mr: 3,
									my: 0,
									ml: 0,
								}}
							/>
						</Fade>
					);
				})}
			</Box>
		);
	}
	return;
};

export default PostedToIcons;
