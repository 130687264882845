import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { styled } from "@mui/material/styles";


const Img = styled("img")({
	margin: "auto",
	display: "block",
//	maxWidth: {md: '300px', lg: '600px'}
});

const Pricing = (props) => {

  return (
    <Box className sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
      <Grid container spacing={6} sx={{
            display:'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: {xs: '475px', sm: '600px', md: '1300px', lg: '1300px'},
            gap: { xs: 5, md: 5, lg: 10},
            flexDirection: 'row',
      }}>
        <Grid item xs={10} md={10} lg={4}>
          <Typography variant="h4" fontWeight={550}sx={{
            paddingBottom: '15px',
            color: '#482fd7'
          }}>
          Simple Pricing
          </Typography>

          <Typography sx={{
                  opacity: '0.7',
                  paddingBottom: '30px',
                  fontSize: '16px',
          }}>
          Access unlimited posting to as many organizations as you want for free. Upgrade to the Pro plan to supercharge your recruiting with premium features.
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={10}
          lg={3}
          sx = {{     
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: '#EDEAFB',
            borderRadius: 5,
            paddingBottom: { xs: 5, lg: 30},
            paddingLeft: 5,
            paddingTop: 5,
        }}
        >
          <Typography variant = "h6" >Free</Typography>
          <Typography variant = "h5"sx={{
            marginTop: 5
        }}>$0 /month</Typography>
          <Typography variant = "h8"sx={{
            marginTop: 5,
            opacity: '0.85',
        }}>Unlimited Posts</Typography>
          <Typography variant = "h8"sx={{
            marginTop: 1,
            opacity: '0.85',
        }}>Engage through Comments</Typography>
        </Grid>
        <Grid
          xs={12}
          md={10}
          lg={3}
          sx = {{     
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: '#EDEAFB',
            borderRadius: 5,
            paddingBottom: { xs: 5, lg: 30},
            paddingLeft: 5,
            paddingTop: 5,
            }}
        >
          <Typography variant = "h6">Pro</Typography>
          <Typography variant = "h5"sx={{
            marginTop: 5,
            
        }}>$100 /month</Typography>
          <Typography variant = "h8"sx={{
            marginTop: 5,
            opacity: '0.85',
        }}>Unlimited Featured Posts
        </Typography>
          <Typography variant = "h8"sx={{
            marginTop: 1,
            opacity: '0.85',
        }}>Advanced Analytics</Typography>
          <Typography variant = "h8"sx={{
            marginTop: 1,
            opacity: '0.85',
        }}>Custom Recommendations</Typography>
          <Typography variant = "h8"sx={{
            marginTop: 1,
            opacity: '0.85',
        }}>View Student Profiles and Resumes</Typography>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Pricing;