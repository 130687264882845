import React from 'react';
import { Grid, Typography, Button, Box, Stack, imageListItemClasses } from '@mui/material';

import MiddleSection from './MiddleSection';
import Hero from './RecruiterHero';
import Analytics from './Analytics';
import Footer from '../Footer';
import OpportunitiesImage from '../Landing/viewposts.png';
import RecruiterSignUp from '../Landing/RecruiterSignUp';
import Pricing from './Pricing';


const Landing = () => {

  return (
    <Stack spacing={{xs: 1, sm: 3, md: 10}} sx = {{
      overflow: "hidden",
      justifyContent: 'center',
    }}>
      <Hero></Hero>
      <MiddleSection
          mainText = {['Post', 'personalized opportunities', 'directly to professional clubs, competitive teams, and academic societies representing', 'thousands of top candidates.']}
          bodyText="Your posts will show up instantly on each group's dedicated job board and weekly email update."
          image={OpportunitiesImage} >
        </MiddleSection>
      <Stack spacing={{xs: 1, sm: 3, md: 10}} sx ={{
              bgcolor: "#FBFBFF",
      }}> 
        {/*
        <Analytics></Analytics>
        <Pricing></Pricing>
        */}
        <RecruiterSignUp></RecruiterSignUp>
      </Stack>
      <Footer></Footer>
    </Stack>
  )}; 

export default Landing;