import { Box, Stack, Typography, IconButton, Button, Switch } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";


import ExpandedCard from "./ExpandedCard";
import ListingsBox from "./ListingsBox";
import SearchBar from "./SearchBar";
//import LoadingPostsCard from "./LoadingPostsCard";
import Loading from "../Loading/Loading";
import SearchIcon from "@mui/icons-material/Search";
import MobileCard from "./MobileCard";
import loading from "../DummyData/Assets/loading";
import {
	getClubFromReference,
	getCompanyByRef,
	getPostFromReference,
} from "../../utilities/firebase";

import NoPosts from "./NoPosts";

const ViewPosts = () => {
	const navigate = useNavigate();
	const [listings, setListings] = useState(null);
	const [companyListings, setCompanyListings] = useState(null);
	const [activeId, setActiveId] = useState(null);
	const [open, setOpen] = React.useState(false);
	const { currentUser, hasCompany } = useAuth();
	const matches = useMediaQuery("(min-width:900px)");
	const today = new Date();
	const [togglePosts, setTogglePosts] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			let posts = {};
			for (let postRef of currentUser.my_posts) {
				const postID = postRef._key.path.segments.at(-1);
				let post = await getPostFromReference(postRef);
				if (post.draft) continue;
				let curr_deadline = post.date_deadline.toDate();
				if (curr_deadline < today) {
					continue;
				}
				posts[postID] = post;
				posts[postID].date_created = posts[postID].date_created.toDate();
				posts[postID].date_deadline = posts[postID].date_deadline.toDate();
			}
			// console.log(currentUser.company._key)
			const company = await getCompanyByRef(currentUser.company)
			const companyPosts = {}
			for (let postRef of company.posts) {
				const postID = postRef._key.path.segments.at(-1);
				let post = await getPostFromReference(postRef);
				if (post.draft) continue;
				let curr_deadline = post.date_deadline.toDate();
				if (curr_deadline < today) {
					continue;
				}
				companyPosts[postID] = post;
				companyPosts[postID].date_created = companyPosts[postID].date_created.toDate();
				companyPosts[postID].date_deadline = companyPosts[postID].date_deadline.toDate();
			}

			setActiveId(Object.keys(companyPosts)[0]);
			setListings(posts);
			setCompanyListings(companyPosts);
		};
		if (!currentUser) {
			return;
		}

		fetchData().catch(console.error);
	}, [currentUser]);


	const handleTogglePosts = () => {
		if (!togglePosts){
			console.log("myPosts")	
		}
		else{
			console.log("companyPosts")
		}
		setTogglePosts(!togglePosts);
	};

	const handleCardClick = (cardId) => {
		setActiveId(cardId);
		if (!matches) {
			setOpen(true);
		}
	};
	const handleClose = () => {
		setOpen(false);
	};
	if (companyListings) {
		if (activeId) {
			return (
				<Box sx={{ mx: { xs: "0px", lg: "100px" }}}>
					<Box
						sx={{
							display: "flex",
							gap: "100px",
							justifyContent: "space-between",
							alignItems: "center",
							mx: { xs: "10px", lg: "0px" },
						}}>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography
								sx={{
									fontWeight: (togglePosts ? 'regular' : 'bold')
								}}
							>
								All Company Posts
							</Typography>
							<Switch onClick={handleTogglePosts} />
							<Typography
								sx={{
									fontWeight: (togglePosts ? 'bold' : 'regular')
								}}
							>
								My Posts
							</Typography>
						</Stack>
						{/* <Button variant='contained'>New Post</Button> */}
						{/* <SearchBar sx={{ display: { xs: "none", md: "block" } }} /> */}
						<IconButton sx={{ display: { xs: "block", md: "none" } }}>
							<SearchIcon sx={{ color: "primary.main" }} />
						</IconButton>
					</Box>
					{/* <Switch onClick={handleTogglePosts}/> */}

					<Stack direction={"row"}>
						<ListingsBox
							clickFunction={handleCardClick}
							activeId={activeId}
							posts={togglePosts ? listings : companyListings}
						/>

						<ExpandedCard listing={companyListings[activeId]} />
						{/* <MobileCard
							listing={listings[activeId]}
							handleClose={handleClose}
							open={open}
						/> */}
					</Stack>
				</Box>
			);
		} else {
			//Maybe should do a No Company instead
			return <NoPosts canPost={hasCompany} />;
		}
	}
	else {
		console.log("here:" + hasCompany);
		if (hasCompany) {
			return <Loading/>;
		} else {
			return <NoPosts canPost={false} />;
		}
	}
};

export default ViewPosts;
