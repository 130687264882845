import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getCompanyByRef, updateRecruiter } from "../../utilities/firebase";

const Profile = () => {
	const { currentUser, refresh } = useAuth();
	const [company, setCompany] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const company = await getCompanyByRef(currentUser.company);
			setCompany(company);
		};
		
		fetchData();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const updatedUser = {
			...currentUser,
			first_name: data.get("first_name"),
			last_name: data.get("last_name"),
		};
		await updateRecruiter(currentUser.user_id, updatedUser);
		alert("Your changes have been saved.");
		refresh();
	};

	if (!currentUser || !company) return;
	return (
		<Box
			sx={{
				flexGrow: 1,
				p: 1,
				width: { sm: `calc(100% - ${240}px)` },
				"& .MuiTextField-root": { m: 1, width: "25ch" },
			}}
			onSubmit={(e) => {
				handleSubmit(e);
			}}
			noValidate
			autoComplete='off'
			component='form'>
			<Typography variant='h5' fontWeight={500}>
				Profile
			</Typography>
			<Typography variant='body1' mt = {1} mb={2}>
				Please fill all required fields to post opportunities.
			</Typography>
			<Box>
				<TextField
					required
					id='first_name'
					name='first_name'
					label='First Name'
					defaultValue={currentUser.first_name}
				/>
				<TextField
					required
					id='last_name'
					name='last_name'
					label='Last Name'
					defaultValue={currentUser.last_name}
				/>
			</Box>

			<Box>
				<TextField
					id='company'
					name='company'
					label='Company'
					defaultValue={company.name}
					disabled
				/>
			</Box>
			<Button
				type='submit'
				variant='contained'
				sx={{ m: 1, backgroundColor: "primary" }}>
				Save Changes
			</Button>
		</Box>
	);
};

export default Profile;
