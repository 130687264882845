import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./utilities/PrivateRoute";

import OpportunityType from "./Components/PostOpportunity/OpportunityType";
import GroupSelect from "./Components/PostOpportunity/GroupSelect";
import OpportunityDetails from "./Components/PostOpportunity/OpportunityDetails";
import DefaultLayout from "./Components/DefaultLayout";
import Landing from "./Components/Landing/Recruiter";
import MyOpportunities from "./Components/MyOpportunities/MyOpportunities.jsx";
import SignIn from "./Components/SignIn";
import SignUp from "./Components/SignUp";
import { AuthProvider } from "./contexts/AuthContext";
import JoinCompany from "./Components/CreateCompany/SelectCompany";
import CreateCompany from "./Components/CreateCompany/CompanyInformation";
import Loading from "./Components/Loading/Loading";
import SettingsLayout from "./Components/Settings/SettingsLayout";
import Profile from "./Components/Profile/Profile";
import NoScrollLayout from "./Components/NoScrollLayout"
import Company from "./Components/ManageCompany/Company";
import Test from "./Components/TestComponents/Test";
import { useMediaQuery } from "@mui/material";
import { Box, Typography } from "@mui/material";


function App() {
	const mobile = useMediaQuery("(min-width:900px)");
	if (!mobile) {
		return (
			<Box textAlign={"center"} paddingTop={"1rem"}>
				<Typography variant='h5'>We're sorry.</Typography>
				<Typography variant='h5'>Mobile is not yet supported.</Typography>
				<Typography variant='body1'>
					Please use a desktop or laptop device to access Inbound
				</Typography>
			</Box>
		);
	}
	return (
		<div className='App'>
			<AuthProvider>
				<Routes>
					{/* Access possible when signed in */}
					<Route element={<PrivateRoute signedIn />}>
						<Route path='settings' element={<SettingsLayout />}>
							<Route index element={<Navigate to='profile' />} />
							<Route path='profile' element={<Profile />} />
							<Route path='company' element={<Company />} />
						</Route>
						<Route path='/myOpportunities' element={<NoScrollLayout />}>
							<Route index element={<MyOpportunities />} />
						</Route>

						{/* Nested access for if they have not yet requested company */}
						<Route element={<PrivateRoute reqCompany={false} />}>
							<Route path='/joinCompany' element={<DefaultLayout />}>
								<Route index element={<JoinCompany />} />
							</Route>
							<Route path='/createCompany' element={<DefaultLayout />}>
								<Route index element={<CreateCompany />} />
							</Route>
						</Route>
					</Route>

					{/* Access when signed in WITH Company */}
					<Route element={<PrivateRoute signedIn hasCompany />}>
						<Route path='/postOpportunity' element={<DefaultLayout />}>
							<Route index element={<Navigate to='/postOpportunity/type' />} />
							<Route
								path='/postOpportunity/type'
								element={<OpportunityType />}
							/>
							<Route
								path='/postOpportunity/details'
								element={<OpportunityDetails />}
							/>
							<Route path='/postOpportunity/groups' element={<GroupSelect />} />
						</Route>
					</Route>

					{/* Cannot Acces When Signed In */}
					<Route element={<PrivateRoute signedIn={false} />}>
						<Route path='/' element={<DefaultLayout />}>
							<Route index element={<Landing />} />
						</Route>
						<Route path='/signin' element={<DefaultLayout />}>
							<Route index element={<SignIn />} />
						</Route>
						<Route path='/signup' element={<DefaultLayout />}>
							<Route index element={<SignUp />} />
						</Route>
					</Route>

					{/* Testing Private Routes */}
					{/* <Route element={<PrivateRoute signedIn hasCompany reqCompany />}>
						<Route path='/privatePath' element={<DefaultLayout />}>
							<Route index element={<Test />} />
						</Route>
					</Route> */}

					{/* Random Loading Route */}
					<Route path='/loading' element={<DefaultLayout />}>
						<Route index element={<Loading />} />
					</Route>
				</Routes>
			</AuthProvider>
		</div>
	);
}

export default App;
