import {
	Box,
	Stack,
	Button,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import React, { useState } from "react";

const CompanyPaper = ({ company, joinCompany }) => {
	//right now hard coded company but should use var passed in
	const handleClick = () => {
		joinCompany();
	};

	return (
		<Box
			id='paper-box'
			sx={{
				boxSizing: "border-box",
				borderRadius: "20px",
				paddingY: "30px",
				paddingLeft: "50px",
				paddingRight: "40px",
				boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
				cursor: "pointer",
			}}>
			<Stack direction='row' justifyContent='space-between'>
				<Box
					display={"flex"}
					alignItems='center'
					justifyContent='space-between'
					flexGrow={10}>
					<Box gap='20px' display={"flex"} alignItems='center'>
						<Box
							component='img'
							src={company.logo}
							sx={{
								height: 75,
								width: 75,
								maxHeight: { xs: 75, md: 75 },
								maxWidth: { xs: 75, md: 75 },
								borderRadius: "10px",
								objectFit: "contain",
							}}
						/>
						<Typography>{company.name}</Typography>
					</Box>
					<Button variant='contained' onClick={handleClick}>
						Join
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

export default CompanyPaper;
