import * as React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import CompanyTabs from "./CompanyTabs";
import { useAuth } from "../../contexts/AuthContext";
import { useState, useEffect } from "react";
import {
	getCompanyByRef,
	getCompanySnap,
	getRecruiterFromRef,
} from "../../utilities/firebase";
import Loading from "../Loading/Loading";

const drawerWidth = 240;

const Company = () => {
	const [members, setMembers] = useState(null);
	const [requests, setRequests] = useState(null);
	const [company, setCompany] = useState(null);
	const [companyRef, setCompanyRef] = useState(null);
	const { currentUser } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!currentUser.company) {
			navigate("/settings");
			return;
		}
		let unsub = () => {};

		const fetchData = async () => {
			const companySnap = await getCompanySnap(
				currentUser.company,
				async (doc) => {
					let companyData = doc.data();
					let membersArr = [];
					let requestsArr = [];
					for (let member of companyData.members) {
						const recruiter = await getRecruiterFromRef(member);
						membersArr.push(recruiter);
					}
					for (let member of companyData.requested) {
						const recruiter = await getRecruiterFromRef(member);
						requestsArr.push(recruiter);
					}
					console.log(membersArr.length);
					setCompanyRef(currentUser.company);
					setMembers(membersArr);
					setRequests(requestsArr);
					setCompany(companyData);
				}
			);
			unsub = companySnap;
		};
		fetchData();
		return unsub;
	}, []);

	const handleBack = () => {
		navigate(-1);
	};

	if (currentUser && members && requests) {
		return (
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 1,
				}}>

				{/*<Box
					container
					onClick={handleBack}
					style={{
						display: "flex",
						alignItems: "center",
					}}
					spacing={1}
					p={1}>
					<ArrowBackIcon m={1} />
					<Typography m={1}>Back</Typography>
				</Box>
				*/}

				<Grid
					container
					direction='row'
					alignItems='center'
					mt={0}
					mb={2}
					spacing={2}>
					<Grid item>
						<Typography variant='h5' fontWeight={500}>{company.name}</Typography>
						<Typography variant='body1' mt={1}>
							Manage your team
						</Typography>
					</Grid>
				</Grid>

				<CompanyTabs
					company={companyRef}
					members={members}
					requests={requests}
				/>
			</Box>
		);
	} else {
		return <Loading></Loading>;
	}
	return <div>Company</div>;
};

export default Company;

// class ClubManage extends React.Component {
// 	// constructor(props) {
// 	// 	super(props);
// 	// 	this.state = {
// 	// 		club: null,
// 	// 		clubRef: null,
// 	// 		members: null,
// 	// 		exec: null,
// 	// 		requests: null,
// 	// 	};
// 	// 	// this.unsub = null;
// 	// }

// 	async componentDidMount() {
// 		if (!this.props.isExec(this.props.clubId.clubId)) {
// 			this.props.navigate("/posts");
// 		}
// 		const clubData = await getClub(
// 			this.props.schoolId,
// 			this.props.clubId.clubId
// 		);
// 		const clubRef = await getClubRef(
// 			this.props.schoolId,
// 			this.props.clubId.clubId
// 		);
// 		if (clubData == null) {
// 			this.props.navigate("/signin");
// 		} else {
// 			this.setState({
// 				club: clubData,
// 				clubRef: clubRef,
// 			});

// 			for (let ref of clubData.execs) {
// 				const uid = ref._key.path.segments.at(-1);
// 				const user = await getUser(uid);
// 				exec.push(user);
// 			}
// 			for (let ref of clubData.members) {
// 				const uid = ref._key.path.segments.at(-1);
// 				const user = await getUser(uid);
// 				members.push(user);
// 			}
// 			for (let ref of clubData.requested) {
// 				const uid = ref._key.path.segments.at(-1);
// 				const user = await getUser(uid);
// 				requests.push(user);
// 			}
// 			this.setState({
// 				members: members,
// 				exec: exec,
// 				requests: requests,
// 			});
// 		}
// 	}
// 	// componentWillUnmount() {
// 	// 	this.unsub();
// 	// }

// handleBack = () => {
// 	this.props.navigate(-1);
// };

// 	handleManageClub = () => {
// 		this.props.navigate(`./manage`);
// 	};
// 	handleChangeMembers = (members) => {
// 		this.setState({
// 			members: members,
// 		});
// 	};
// 	handleAddMembers = (newMembers) => {
// 		let result = this.state.members.concat(newMembers);
// 		this.setState({
// 			members: result,
// 		});
// 	};
// 	handleChangeExec = (exec) => {
// 		this.setState({
// 			exec: exec,
// 		});
// 	};
// 	handleChangeRequests = (requests) => {
// 		this.setState({
// 			requests: requests,
// 		});
// 	};

// 	render() {
// 		if (this.state.club) {
// 			return (
// 				<Box
// 					component='main'
// 					sx={{
// 						flexGrow: 1,
// 						p: 1,
// 						width: { sm: `calc(100% - ${drawerWidth}px)` },
// 					}}>
// 					<Box
// 						container
// 						onClick={this.handleBack}
// 						style={{
// 							display: "flex",
// 							alignItems: "center",
// 						}}
// 						spacing={1}
// 						p={1}>
// 						<ArrowBackIcon m={1} />
// 						<Typography m={1}>Back</Typography>
// 					</Box>

// 					<Grid
// 						container
// 						direction='row'
// 						alignItems='center'
// 						mt={2}
// 						mb={2}
// 						spacing={4}>
// 						<Grid item>
// 							<Typography variant='h4'>{this.state.club.name}</Typography>
// 						</Grid>
// 					</Grid>

// 					<ClubManageTabs
// 						exec={this.state.exec}
// 						members={this.state.members}
// 						requests={this.state.requests}
// 						clubRef={this.state.clubRef}
// 						handleChangeExec={this.handleChangeExec}
// 						handleChangeMembers={this.handleChangeMembers}
// 						handleAddMembers={this.handleAddMembers}
// 						handleChangeRequests={this.handleChangeRequests}
// 					/>
// 				</Box>
// 			);
// 		} else {
// 			return <Typography variant='h1'>Loading...</Typography>;
// 		}
// 	}
// }

// function (props) {
// const schoolId = "northwestern";
// const clubId = useParams();
// const { currentUser, isExec, inClub } = useAuth();
// const navigate = useNavigate();
// return (
// <ClubManage
// {...props}
// navigate={navigate}
// schoolId={schoolId}
// clubId={clubId}
// currentUser={currentUser}
// isExec={isExec}
// />
// );
// }
