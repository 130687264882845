import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { promoteUsers, removeUsers } from "../../../utilities/firebase";

const options = ["Remove"];

const ITEM_HEIGHT = 48;

const ClubExecMenu = (props) => {
	const { selectedUIDs, handleRemoveRows } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (option) => {
		setAnchorEl(null);
		if (option == "Remove") {
			// Do things
		}
	};
	const handleRemove = () => {
		// removeUsers(selectedUIDs, clubRef);
	};

	return (
		<div>
			<IconButton
				aria-label='more'
				id='long-button'
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup='true'
				onClick={handleClick}>
				<MoreVertIcon id='button-icon' />
			</IconButton>
			<Menu
				id='long-menu'
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: "20ch",
					},
				}}>
				{options.map((option) => (
					<MenuItem
						key={option}
						selected={option === "Pyxis"}
						onClick={() => handleClose(option)}>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default ClubExecMenu;
