import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import validator from 'validator'
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Slider,
	Select,
	MenuItem,
	Typography,
	Stack,
} from "@mui/material";
import { width } from "@mui/system";
import { editPost, getPost } from "../../utilities/firebase";
import { useState } from "react";






const myHelper = {
	email: {
		required: "Email is Required",
		pattern: "Invalid Email Address",
	},
	name: {
		required: "Application name is Required",
	},
	title: {
		required: "Application title is Required",
	},
	url: {
		required: "Application URL is Required",
	},
	deadline: {
		required: "Application deadline is Required",
	},
	description: {
		required: "Description is Required",
	},
	city: {
		required: "City is Required",
	},
	state: {
		required: "State is Required",
	},
};

const states = [
	"USA",
	"AL",
	"AK",
	"AS",
	"AZ",
	"AR",
	"CA",
	"CO",
	"CT",
	"DE",
	"DC",
	"FM",
	"FL",
	"GA",
	"GU",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"KS",
	"KY",
	"LA",
	"ME",
	"MH",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"MO",
	"MT",
	"NE",
	"NV",
	"NH",
	"NJ",
	"NM",
	"NY",
	"NC",
	"ND",
	"MP",
	"OH",
	"OK",
	"OR",
	"PW",
	"PA",
	"PR",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VT",
	"VI",
	"VA",
	"WA",
	"WV",
	"WI",
	"WY",
];

export default function App() {

	const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
	const location = useLocation();
	const navigate = useNavigate();
	const postId = location.state.postId;
	const [deadlineValue, setDeadlineValue] = useState(null);
	const [data, setData] = useState();
	const { control, handleSubmit, setValue } = useForm({
		reValidateMode: "onBlur",
		defaultValues: {
			locations: [{ city: "", state: "" }],
		},
	});

	const {
		fields: locations,
		append: appendLocationRow,
		remove: removeLocationRow,
		replace: setLocations,
	} = useFieldArray({
		control,
		name: "locations",
	});

	useEffect(() => {
		const fetchData = async () => {
			const postData = await getPost(postId);
			let parsedData = null;
			if (postData.date_deadline) {
				let date = postData.date_deadline.toDate();
				parsedData = moment(date);
			}
			if (postData.locations) {
				let newLocations = [];
				postData.locations.forEach((location) => {
					const temp = location.split(", ");
					newLocations.push({ city: temp[0], state: temp[1] });
				});
				setLocations(newLocations);
			}
			setDeadlineValue(parsedData);
			setData(postData);
		};
		fetchData();
	}, []);

	const handleOnSubmit = async (evt) => {
		// We need company/image
		// company, image
		if (evt.locations.length == 0) {
			alert('Location is required');
			return;
		}

		let parsedLocations = evt.locations.map((location) => {
			let city = location.city.trim().split(" ");
			city = city.map((word) => {
				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
			});
			city = city.join(" ");

			return `${city}, ${location.state}`;
		});

		let formattedDescription;

		const newData = {
			description: evt.description,
			title: evt.title,
			link: evt.url,
			date_deadline: deadlineValue.toDate(),
			locations: parsedLocations,
			primary_contact: evt.fullName,
			contact_email: evt.email,
			field: "",
		};

		await editPost(postId, newData);
		navigate("/postOpportunity/groups", {
			state: { postId: postId },
		});
	};

	const addNewLocation = () => appendLocationRow({ city: "", state: "" });
	if (data) {
		return (
			<Box
				component='form'
				onSubmit={handleSubmit(handleOnSubmit)}
				sx={{
					alignItems: "center",
					justifyContent: "center",
					marginX: 10,
					marginBottom: 4,
				}}>
				<Stack gap={3} marginTop={"25px"}>
					<div>
						<Typography variant='h5' fontWeight={500}>Post an Opportunity</Typography>
						<Typography variant='body1'>2. Edit Post Details</Typography>
					</div>
					<Typography variant='caption'>
						To add the location of a remote opportunity, set the city as "Remote" and the state as "USA".
					</Typography>
					<Typography variant='caption'>
						Once the date of the application deadline has passed, this opportunity will no longer be visible. 
					</Typography>
					<Typography variant='h7' fontWeight={500}>
						Overview
					</Typography>
					<Grid item xs={12}>
						<Controller
							control={control}
							name='title'
							rules={{
								required: true,
							}}
							defaultValue={data.title}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									variant='outlined'
									label='Title'
									error={error !== undefined}
									helperText={error ? myHelper.title[error.type] : ""}
								/>
							)}
						/>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={6}>
						<Controller
							control={control}
							name='url'
							defaultValue={data.link}
							rules={{
								required: true,
								pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
							}}
							isValid={(value) => urlRegex.test(value)}
							render={({ field, fieldState: { error } }) => (
								<TextField
								  {...field}
								  fullWidth
								  variant='outlined'
								  label='Application URL'
								  error={error !== undefined}
								  helperText={error && error.type === "pattern" ? "Invalid URL" : error ? myHelper.url[error.type] : ""}
								/>
							)}
						/>
						</Grid>

						<Grid item xs={6}>
							<Controller
								control={control}
								name='deadline'
								defaultValue={deadlineValue}
								rules={{
									required: true,
								}}
								render={({
									field: { onChange, ...restField },
									fieldState: { error },
								}) => (
									<LocalizationProvider dateAdapter={AdapterMoment}>
										{/* <TextField
											{...field}
											fullWidth
											variant='outlined'
											label='Application Deadline'
											error={error !== undefined}
											helperText={error ? myHelper.deadline[error.type] : ""}
										/> */}
										<DateTimePicker
											value={deadlineValue ? deadlineValue : null}
											onChange={(event) => {
												onChange(event);
												setDeadlineValue(event);
											}}
											minDate={moment()}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													label='Application Deadline'
													error={error !== undefined}
													helperText={
														error ? myHelper.deadline[error.type] : ""
													}
												/>
											)}
											{...restField}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
					</Grid>

					<Grid item xs={6} spacing={3}>
						<Controller
							control={control}
							name='description'
							defaultValue={data.description}
							rules={{
								required: true,
							}}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									rows={6}
									multiline
									variant='outlined'
									label='Description'
									error={error !== undefined}
									helperText={error ? myHelper.description[error.type] : ""}
								/>
							)}
						/>
					</Grid>

					<Typography variant='h7' fontWeight={500}>
						Location
					</Typography>
					<Grid container xs={12} gap={2}>
						{locations.map((field, index) => (
							<Grid container key={field.id} spacing={1} alignItems='center'>
								<Grid item xs={6}>
									<Controller
										control={control}
										// must use . for the object key!!!
										name={`locations.${index}.city`}
										rules={{
											required: true,
										}}
										defaultValue=''
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant='outlined'
												label='City'
												fullWidth
												error={error !== undefined}
												helperText={error ? myHelper.city[error.type] : ""}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={4}>
									<Controller
										control={control}
										// must use . for the object key!!!
										name={`locations.${index}.state`}
										rules={{
											required: true,
										}}
										render={({ field, fieldState: { error } }) => (
											<Select
												{...field}
												fullWidth
												error={error !== undefined}
												MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
												helperText={error ? myHelper.state[error.type] : ""}>
												{states.map((state) => (
													<MenuItem value={state}>{state}</MenuItem>
												))}
											</Select>
										)}
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										color='error'
										variant='text'
										onClick={() => removeLocationRow(index)}>
										Delete
									</Button>
								</Grid>
							</Grid>
						))}
						<Button
							onClick={() => {
								addNewLocation();
							}}>
							Add
						</Button>
					</Grid>

					<Typography variant='h7' fontWeight={500}>
						Contact Details
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Controller
								control={control}
								name='fullName'
								defaultValue={data.primary_contact}
								rules={{
									required: true,
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										fullWidth
										variant='outlined'
										label='Full name'
										error={error !== undefined}
										helperText={error ? myHelper.name[error.type] : ""}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={6}>
							<Controller
								control={control}
								name='email'
								defaultValue={data.contact_email}
								rules={{
									required: true,
									pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										type='email'
										fullWidth
										label='Email'
										error={error !== undefined}
										helperText={error ? myHelper.email[error.type] : ""}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Button variant='contained' type='submit'>
							Save
						</Button>
					</Grid>
				</Stack>
			</Box>
		);
	}
}

/**
 * 
 *           <Grid item xs={6}>
            <Controller
              control={control}
              name="Application URL"
              defaultValue=""
              render={({ field }) => (
                <DatePicker
                disableFuture
                label="Responsive"
                openTo="year"
                views={['year', 'month', 'day']}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </Grid>
 */
