import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Typography, Stack } from "@mui/material";
import CompanyPaper from "./CompnayPaper";
import { getAllCompanies, requestJoinCompany } from "../../utilities/firebase";
import { useAuth } from "../../contexts/AuthContext";
//import EmailInput from "./EmailInput.js";

const myHelper = {
	email: {
		required: "Email is Required",
		pattern: "Invalid Email Address",
	},
	name: {
		required: "Company name is Required",
	},
};

export default function CompnayInformation() {
	const [companies, setCompanies] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const { currentUser, refresh } = useAuth();
	const [deadlineValue, setDeadlineValue] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const companyData = await getAllCompanies();
			setCompanies(companyData);
		};
		fetchData();
	}, []);

	const { control, handleSubmit, setValue } = useForm({
		reValidateMode: "onBlur",
		defaultValues: {
			locations: { city: "", state: "" },
		},
	});

	const {
		fields: locations,
		append: appendLocationRow,
		remove: removeLocationRow,
		replace: setLocations,
	} = useFieldArray({
		control,
		name: "locations",
	});

	const handleOnCreateCompany = () => {
		navigate("/createCompany");
	};
	const handleJoinCompany = async (companyID) => {
		await requestJoinCompany(companyID, currentUser.user_id);
		refresh();
		navigate("/myOpportunities");
	};

	if (companies[0])
		return (
			<Box
				sx={{
					alignItems: "center",
					justifyContent: "center",
					marginX: 10,
					marginBottom: 4,
				}}>
				<Stack gap={3} marginTop={10}>
					<Stack direction='row' justifyContent='space-between'>
						<Typography variant='h5'>Your Company</Typography>
					</Stack>
					<Typography variant='h7' fontWeight={300}>
						Create a company account...
					</Typography>
					<Box sx={{ display: "flex", mb: 2, flexDirection: "column", alignItems: "center" }}>
						<Button onClick={handleOnCreateCompany} variant='contained'>
							Create a new company
						</Button>
					</Box>
					<Typography variant='h7' fontWeight={300}>
						...or request to join an existing company below. Your request must be approved by a company account admin.
					</Typography>
					{companies.map((company) => {
						return (
							<CompanyPaper
								company={company}
								key={company.id}
								joinCompany={() =>
									handleJoinCompany(company.id)
								}></CompanyPaper>
						);
					})}
				</Stack>
			</Box>
		);
}
