import createTheme from "@mui/material/styles/createTheme";
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";

let theme = createTheme({
	palette: {
		primary: {
			main: "#482fd7",
		},
		secondary: {
			main: "#ff8800",
		},
		white: "#fff",
	},
	typography: {
		fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
		fontSize: 16,
		htmlFontSize: 16,
		body1: {
			fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
		},
		body2: {
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			fontSize: "1.25rem",
			fontWeight: 400,
		},
		subtitle2: {
			fontWeight: 300,
			fontFamily: ' "Roboto", "Arial", sans-serif',
		},
	},
});

theme = responsiveFontSizes(theme);

export default theme;
