import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { signIn, getCurrentUser } from "../utilities/firebase";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

class SignIn extends React.Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
		if (this.props.currentUser != null) {
			this.props.navigate("/myOpportunities");
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const success = await signIn(data.get("email"), data.get("password"));
		if (success) {
			this.props.navigate("/myOpportunities");
		}
	}

	render() {
		return (
			<Container component='main' maxWidth='xs' sx={{ marginBlock: "auto" }}>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					<Box
						component='form'
						noValidate
						onSubmit={this.handleSubmit}
						sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									autoFocus
									name='email'
									id='email'
									label='Email Address'
									autoComplete='email'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name='password'
									id='password'
									label='Password'
									type='password'
									autoComplete='new-password'
								/>
							</Grid>
						</Grid>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}>
							Sign In
						</Button>
						<Grid container justifyContent='flex-end'>
							<Grid item>
								<Link component={RouterLink} to='/signup' variant='body2'>
									Don't have an account? Sign up
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();
	const { currentUser } = useAuth();
	return <SignIn {...props} navigate={navigate} currentUser={currentUser} />;
}
