// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { useState, useEffect } from "react";
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	createUserWithEmailAndPassword,
	setPersistence,
	browserSessionPersistence,
} from "firebase/auth";
import {
	getFirestore,
	doc,
	addDoc,
	setDoc,
	getDoc,
	deleteDoc,
	getDocs,
	collection,
	updateDoc,
	arrayUnion,
	arrayRemove,
	query,
	where,
	Timestamp,
	onSnapshot,
} from "firebase/firestore";
import { dblClick } from "@testing-library/user-event/dist/click";
import { PostAddRounded } from "@mui/icons-material";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDQ17HMsKEC8LD09HmxhYSRUCFZDUcqpfw",
	authDomain: "inbound-careers.firebaseapp.com",
	databaseURL: "https://inbound-careers-default-rtdb.firebaseio.com/",
	projectId: "inbound-careers",
	storageBucket: "inbound-careers.appspot.com",
	messagingSenderId: "428689903225",
	appId: "1:428689903225:web:6cd1f7ed188e4143de0ea8",
	measurementId: "G-V9SSLBJGGP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth();
(async () => {
	await setPersistence(auth, browserSessionPersistence);
})();

// Sign in with Google
export const signInWithG = async () => {
	signInWithPopup(getAuth(app), new GoogleAuthProvider());
};

// Sign in with Email/password
export const signIn = async (email, password) => {
	let success = false;
	await signInWithEmailAndPassword(auth, email, password)
		.then((res) => {
			success = true;
			console.log(res);
		})
		.catch((error) => {
			const errorMessage = error.message;
			alert(errorMessage.toString());
		});
	return success;
};

// Sign up with Email/password, requires last name and first name to be updated in the user profile
export const signUp = async (email, password, last_name, first_name) => {
	await createUserWithEmailAndPassword(auth, email, password)
		.then(async (userCredential) => {
			const user = userCredential.user;

			if (last_name !== "" && first_name !== "") {
				// updateDoc(doc(firestore, "users", user.uid, "last_name"), last_name);
				// updateDoc(doc(firestore, "users", user.uid, "first_name"), first_name);
				await setDoc(doc(firestore, "recruiters", user.uid), {
					email: user.email,
					createdAt: new Date().toString(),
					first_name: first_name,
					last_name: last_name,
					saved_posts: [],
					my_posts: [],
					profile_pic: "",
					user_id: user.uid,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.message;
			alert(errorMessage.toString());
		});
};

// Get User
export const getCurrentUser = async () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			// unsubscribe();
			resolve(user);
		}, reject);
	});
};
// Get User Id
export const getUID = async () => {
	return auth.currentUser.uid;
};

// Sign out
export const signOutUser = async () => {
	await signOut(getAuth(app));
};

// get user state who signed in to the account
export const useUserState = async () => {
	const [user, setUser] = useState(true);

	useEffect(() => {
		onAuthStateChanged(getAuth(app), (user) => {
			if (user) {
				setUser(user);
				// initializeNewUser(user);
			} else {
				setUser(null);
			}
		});
	}, [user]);
	return user;
};

// export const AllowIfAuth = () => {
//   const [user, loading, error] = useAuthState(auth);
//   if (loading) {
//       return "loading";
//   } else if (user) {
//       return "success";
//   } else {
//     return null;
//   }
// }

// creates an empty post for user to edit and adds it as a "draft" to their company
export const createEmptyPost = async (jobType, companyRef, recruiterId) => {
	const recruiterRef = doc(firestore, "recruiters", recruiterId);
	const companySnap = await getDoc(companyRef);
	let companyData;
	if (companySnap.exists()) {
		companyData = companySnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
	console.log(companyData);

	const postRef = await addDoc(collection(firestore, "posts"), {
		company: companyData.name,
		image: companyData.logo,
		job_type: jobType,
		draft: true,
		date_created: Timestamp.now(),
	});
	setDoc(postRef, { id: postRef.id }, { merge: true });
	await updateDoc(recruiterRef, {
		my_posts: arrayUnion(postRef),
	});

	await updateDoc(companyRef, {
		posts: arrayUnion(postRef)
	})

	return (await postRef).id;
};

export const editPost = async (post_id, newData) => {
	const postRef = await doc(firestore, "posts", post_id);
	await setDoc(postRef, newData, { merge: true });
	alert("Post Saved");
};

export const modifyGroupsOnPost = async (post_id, ids, removedIDs) => {
	const postRef = await doc(firestore, "posts", post_id);
	const groupArray = ids.map((obj) => {
		const groupRef = doc(
			firestore,
			"schools",
			obj.school_id,
			"clubs",
			obj.group_id
		);
		return groupRef;
	});
	const removeArray = removedIDs.map((obj) => {
		const groupRef = doc(
			firestore,
			"schools",
			obj.school_id,
			"clubs",
			obj.group_id
		);
		return groupRef;
	});
	for (let groupRef of groupArray) {
		console.log(groupRef);
		await updateDoc(groupRef, {
			posts: arrayUnion(postRef),
		});
	}
	for (let groupRef of removeArray) {
		await updateDoc(groupRef, {
			posts: arrayRemove(postRef),
		});
	}
	await setDoc(
		postRef,
		{ posted_to: groupArray, draft: false },
		{ merge: true }
	);
};

export const deletePost = async (post_id, recruiter_id) => {
	// access the doc (postedTo field) use arrayRemove
	const postRef = doc(firestore, "posts", post_id)
	const postSnap = await getDoc(postRef)
	const postData = postSnap.data()
	const recRef = doc(firestore, "recruiters", recruiter_id)
	const recSnap = await getDoc(recRef)
	const companyRef = recSnap.data().company
	
	updateDoc(recRef, {
		my_posts: arrayRemove(postRef)
	})

	updateDoc(companyRef, { // delete post in company
		posts: arrayRemove(postRef)
	})

	for (let groupRef of postData.posted_to) { // spits out all instances
		console.log(groupRef)
		updateDoc(groupRef, {
			posts: arrayRemove(postRef)
		})
	}


	await deleteDoc(postRef);
	
	alert(`Deleted Post. Reload to see changes.`);
	// TODO: Remove from Saved when thats a feature (Both Student and Rec)
};

// create post with a post object (post_id will be automatically generated)
// DEPRECATED
export const createPost = async ({ post }) => {
	const docRef = doc(firestore, "posts");
	setDoc(docRef, post);
};

// get post with a post_id
export const getPost = async (post_id) => {
	const docRef = doc(firestore, "posts", post_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// get all posts
export const getPosts = async () => {
	const querySnapshot = await getDocs(collection(firestore, "posts"));
	const posts = [];
	querySnapshot.forEach((doc) => {
		posts.push(doc.data());
	});
	return posts;
};

export const createCompany = async (name, logo, recruiterId) => {
	console.log(`creating ${name}`);
	const recruiterRef = doc(firestore, "recruiters", recruiterId);
	const companyRef = await addDoc(collection(firestore, "companies"), {
		logo: logo,
    verified: true,
		name: name,
		members: [recruiterRef],
		requested: [],
		posts: []
	});

	await setDoc(recruiterRef, { company: companyRef }, { merge: true });
	alert("Success!");
};

export const getAllCompanies = async () => {
	const companies = [];
	const q = query(
		collection(firestore, "companies"),
		where("verified", "==", true)
	);

	const querySnapshot = await getDocs(q);
	querySnapshot.forEach((doc) => {
		companies.push({ ...doc.data(), id: doc.id });
	});
	return companies;
};

export const getCompany = async (companyID) => {
	const companyRef = doc(firestore, "companies", companyID);
	const companySnap = await getDoc(companyRef);
	if (companySnap.exists()) {
		return companySnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

export const getCompanyByRef = async (companyRef) => {
	const companySnap = await getDoc(companyRef);
	console.log("infirebase", companySnap)
	if (companySnap.exists()) {
		return companySnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

export const getCompanySnap = async (companyRef, callback) => {
	const unsubscribe = onSnapshot(companyRef, callback);
	return unsubscribe;
};

export const requestJoinCompany = async (companyID, userID) => {
	const companyRef = doc(firestore, "companies", companyID);
	const userRef = doc(firestore, "recruiters", userID);
	await updateDoc(companyRef, {
		requested: arrayUnion(userRef),
	});
	await updateDoc(userRef, {
		requested: true,
	});
	alert("Request Sent");
};

export const inviteMembers = async (emails) => {
	emails.forEach((email) => {
		// Send email with another helper or something confirming join
	});
};

// get club reference with a club_id and school_id
export const getClubRef = async (school_id, club_id) => {
	const docRef = doc(firestore, "schools", school_id, "clubs", club_id);
	return docRef;
};

// get club with a club_id and school_id
export const getClub = async (school_id, club_id) => {
	const docRef = doc(firestore, "schools", school_id, "clubs", club_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};
export const requestToClub = async (club_ref, user_id) => {
	const userRef = doc(firestore, "users", user_id);
	await updateDoc(club_ref, {
		requested: arrayUnion(userRef),
	});
};

// get club with a club reference
export const getClubFromReference = async (club_ref) => {
	const docSnap = await getDoc(club_ref);
	if (docSnap.exists()) {
		var data = docSnap.data();
		data["id"] = docSnap.id;
		return data;
	} else {
		console.log("No such document!");
		return null;
	}
};

/**
 * Get a list of clubs from a school_id in format [club, clubUID, school_name]
 *
 * @param   {string} school_id The ID of the school you are pulling from
 * @returns {[{}, String, String]} An array with values [club: Object, clubUID: String, school_name: String]
 */
export const getClubs = async (school_id) => {
	const querySnapshot = await getDocs(
		collection(firestore, "schools", school_id, "clubs")
	);
	const schoolRef = doc(firestore, "schools", school_id);
	const schoolSnapshot = await getDoc(schoolRef);
	const schoolName = schoolSnapshot.data().name;
	const clubs = [];
	querySnapshot.forEach((doc) => {
		const uid = doc._key.path.segments.at(-1);
		clubs.push([doc.data(), uid, schoolName, school_id]);
	});
	return clubs;
};

// get post with a company id
export const getPostsByCompany = async (comany_id) => {
	const querySnapshot = await getDocs(collection(firestore, "posts"));
	const posts = [];
	querySnapshot.forEach((doc) => {
		if (doc.data().company_id === comany_id) {
			posts.push(doc.data());
		}
	});
	return posts;
};

export const getPostFromReference = async (post_ref) => {
	const docSnap = await getDoc(post_ref);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// update post with a post object
export const updatePost = async (post_id, post) => {
	const docRef = doc(firestore, "posts", post_id);
	await updateDoc(docRef, post);
};

// create a new user with a user object
export const createUser = async (user) => {
	const docRef = doc(firestore, "users", user.uid);
	setDoc(docRef, user);
};

// get user with a user_id
export const getUser = async (user_id) => {
	const docRef = doc(firestore, "users", user_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// get all users
export const getUsers = async () => {
	const querySnapshot = await getDocs(collection(firestore, "users"));
	const users = [];
	querySnapshot.forEach((doc) => {
		users.push(doc.data());
	});
	return users;
};

// update user with a user object, if user is not found, create a new user
export const updateRecruiter = async (user_id, user) => {
	const docRef = doc(firestore, "recruiters", user_id);
	updateDoc(docRef, user);
};

export const acceptUsers = async (user_ids, companyRef) => {
	let newMembers = [];
	for (let uid of user_ids) {
		let userRef = doc(firestore, "recruiters", uid);
		console.log(companyRef);
		await updateDoc(companyRef, {
			members: arrayUnion(userRef),
			requested: arrayRemove(userRef),
		});
		await setDoc(
			userRef,
			{
				company: companyRef,
			},
			{ merge: true }
		);
		let newMember = await getUser(uid);
		newMembers.push(newMember);
	}
	return newMembers;
};

export const rejectUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "recruiters", uid);
		await updateDoc(clubRef, {
			requested: arrayRemove(userRef),
		});
	}
};

export const promoteUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			execs: arrayUnion(userRef),
		});
		await updateDoc(userRef, {
			exec_for: arrayUnion(clubRef),
		});
	}
};

export const demoteUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			execs: arrayRemove(userRef),
		});
		await updateDoc(userRef, {
			exec_for: arrayRemove(clubRef),
		});
	}
};

export const removeUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			members: arrayRemove(userRef),
			execs: arrayRemove(userRef),
		});
		await updateDoc(userRef, {
			clubs: arrayRemove(clubRef),
			exec_for: arrayRemove(clubRef),
		});
	}
};

export const removeUser = async (user_id, school_id, club_id) => {
	const userRef = doc(firestore, "users", user_id);
	const clubRef = doc(firestore, "schools", school_id, "clubs", club_id);
	await updateDoc(clubRef, {
		members: arrayRemove(userRef),
		execs: arrayRemove(userRef),
	});
	await updateDoc(userRef, {
		clubs: arrayRemove(clubRef),
		exec_for: arrayRemove(clubRef),
	});
};

//
//
// RECRUITER FUNCTIONS
//
//

// Gets data for the given recruiter
export const getRecruiter = async (user_id) => {
	const docRef = doc(firestore, "recruiters", user_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};
export const getRecruiterFromRef = async (userRef) => {
	const docSnap = await getDoc(userRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// setData on a specific path
export const setData = (path, value) => setDoc(doc(firestore, path), value);

export const pushToFirebase = async (route, userid, data) => {
	if (data) {
		try {
			await setData(`users/${userid}/info/${route}`, data);
			console.log("pushed to firebase");
		} catch (error) {
			alert(error);
		}
	}
};
