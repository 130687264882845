import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import OptionCard from "./OptionCard";
import { useNavigate } from "react-router-dom";
import { createEmptyPost } from "../../utilities/firebase";
import { useAuth } from "../../contexts/AuthContext";

const OpportunityType = () => {
	const [selected, setSelected] = useState(-1);

	const [selectedFullTime, setSelectedFullTime] = useState(false);
	const [selectedInternship, setSelectedInternship] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(false);
	const [selectedOther, setSelectedOther] = useState(false);

	const { currentUser, refresh } = useAuth();
	const navigate = useNavigate();

	console.log(currentUser);

	const navigateNext = async () => {
		let postId;
		if (selectedFullTime) {
			postId = await createEmptyPost(
				"Full-Time",
				currentUser.company,
				currentUser.user_id
			);
		} else if (selectedInternship) {
			postId = await createEmptyPost(
				"Internship",
				currentUser.company,
				currentUser.user_id
			);
		} else if (selectedEvent) {
			postId = await createEmptyPost(
				"Event",
				currentUser.company,
				currentUser.user_id
			);
		} else if (selectedOther) {
			postId = await createEmptyPost(
				"Other",
				currentUser.company,
				currentUser.user_id
			);
		}
		refresh();
		navigate("/postOpportunity/details", {
			state: { postId: postId },
		});
	};

	const handleSelectFullTime = () => {
		setSelectedFullTime(true);
		setSelectedInternship(false);
		setSelectedEvent(false);
		setSelectedOther(false);
	};

	const handleSelectInternship = () => {
		setSelectedFullTime(false);
		setSelectedInternship(true);
		setSelectedEvent(false);
		setSelectedOther(false);
	};
	const handleSelectEvent = () => {
		setSelectedFullTime(false);
		setSelectedInternship(false);
		setSelectedEvent(true);
		setSelectedOther(false);
	};
	const handleSelectOther = () => {
		setSelectedFullTime(false);
		setSelectedInternship(false);
		setSelectedEvent(false);
		setSelectedOther(true);
	};
	return (
		<Box sx={{ mx: { xs: "0px", lg: "120px" } }}>
			<Stack
				sx={{
					mx: { xs: "120px", lg: "120px" },
					marginTop: "25px",
					rowGap: "50px",
				}}>
				<div>
					<Typography variant='h5' fontWeight={500}>Post an Opportunity</Typography>
					<Typography variant='body1'>1. Select Post Type</Typography>
				</div>
				<Box
					sx={{
						display: "flex",
						rowGap: "50px",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "40px",
							justifyContent: "center",
							flexWrap: "wrap",
						}}>
						<ButtonBase disableRipple onClick={handleSelectFullTime}>
							<OptionCard text='Full-time role' isActive={selectedFullTime} />
						</ButtonBase>
						<ButtonBase disableRipple onClick={handleSelectInternship}>
							<OptionCard text='Internship' isActive={selectedInternship} />
						</ButtonBase>

						<ButtonBase disableRipple onClick={handleSelectEvent}>
							<OptionCard text='Event' isActive={selectedEvent} />
						</ButtonBase>
						<ButtonBase disableRipple onClick={handleSelectOther}>
							<OptionCard text='Other' isActive={selectedOther} />
						</ButtonBase>
					</Stack>

					<Button
						variant='contained'
						onClick={navigateNext}
						style={{
							maxWidth: "90px",
							maxHeight: "40px",
							minWidth: "90px",
							minHeight: "40px",
						}}>
						Next
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

export default OpportunityType;
