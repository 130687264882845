import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Radio,
	Chip,
	RadioGroup,
	TextField,
	Slider,
	Select,
	MenuItem,
	Typography,
	Stack,
} from "@mui/material";
import { useState } from "react";
import { createCompany } from "../../utilities/firebase";
import { useAuth } from "../../contexts/AuthContext";
//import EmailInput from "./EmailInput.js";

const myHelper = {
	email: {
		required: "Email is Required",
		pattern: "Invalid Email Address",
	},
	name: {
		required: "Company Name is Required",
	},
	logo: {
		required: "Company Logo is Required",
	}
};

export default function CompnayInformation() {
	const location = useLocation();
	const navigate = useNavigate();
	const { currentUser, refresh } = useAuth();
	const { control, handleSubmit, setValue } = useForm({
		reValidateMode: "onBlur",
		defaultValues: {
			locations: { city: "", state: "" },
		},
	});

	const handleOnSubmit = async (evt) => {
		await createCompany(evt["Company Name"], evt["Company Logo"], currentUser.user_id);
		refresh();
		navigate("/myOpportunities");
		// email solution for inviting
	};

	return (
		<Box
			component='form'
			onSubmit={handleSubmit(handleOnSubmit)}
			sx={{
				alignItems: "center",
				justifyContent: "center",
				marginX: 10,
				marginBottom: 4,
			}}>
			<Stack gap={3} marginTop={10}>
				<Typography variant='h5'>Input Company Information</Typography>
				<Typography variant='h7' fontWeight={300}>
					We manually verify all new companies within 24 hours for platform security
				</Typography>
				<Typography variant='h7' fontWeight={500}>
					Overview
				</Typography>
				<Grid item xs={12} >
					<Controller
						control={control}
						name='Company Name'
						rules={{
							required: true,
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								sx={{
									marginBottom: 4,
								}}
								fullWidth
								variant='outlined'
								label='Company Name'
								error={error !== undefined}
								helperText={error ? myHelper.name[error.type] : ""}
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name='Company Logo'
						rules={{
							required: true,
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								fullWidth
								variant='outlined'
								label='Company Logo'
								error={error !== undefined}
								helperText={error ? myHelper.name[error.type] : "Paste the image address of a square version of the logo"}
								required
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Button variant='contained' type='submit'>
						Create Company
					</Button>
				</Grid>
			</Stack>
		</Box>
	);
}
